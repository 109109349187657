import { WixMobileAppName } from '@wix/ambassador-one-app-preferred-apps/types';

export const NAMESPACE = 'Wix_Ads';
export const wixLogoPlaceHolder = '{Wix}';

export const AppsNames = {
  ...WixMobileAppName,
  Default: 'WixOneApp',
};

export const TranslationKeys = {
  genericApp: 'Mobile_Site_Banner_Widget',
  spacesApp: 'lp.mobile.better.title.WixOneApp',
  fitnessApp: 'lp.mobile.better.title.WixFitnessApp',
  dineApp: 'lp.mobile.better.title.WixRestaurantsApp',
};

export const enum TranslationFallbacks {
  genericApp = 'Join us on the {Wix} app',
  WixOneApp = 'Join us on the Spaces by Wix app',
  WixFitnessApp = 'Join us on the Fit by Wix app',
  WixRestaurantsApp = 'Join us on the Dine by Wix app',
}

export const shouldUsePreferredAppExperiment =
  'specs.thunderbolt.freemiumBannerMobileUsePreferredApp';
